import React from 'react';
import s from './App.module.css';
import {Route, Routes} from "react-router-dom";
import Home from "./pages/PublicPages/Home/Home";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import {
    ABOUT,
    BUSINESS_ACCOUNT,
    CAREERS,
    COMPLAINTS,
    CONTACT_US,
    CONTACT_US_FOR_AGENTS,
    COOKIE_POLICY,
    FAQ,
    AGENT_PROGRAM,
    PERSONAL_ACCOUNT,
    PRICING,
    PRICING_BUSINESS_ACCOUNT,
    PRICING_PERSONAL_ACCOUNT,
    PRIVACY_POLICY,
    TERMS_AND_CONDITIONS, TERMS_AND_CONDITIONS_BUSINESS,
    TERMS_AND_CONDITIONS_PERSONAL
} from "./Constants/RoutesConstants";
import Contacts from "./pages/PublicPages/Contacts/Contacts";
import Faq from "./pages/PublicPages/Faq/Faq";
import About from "./pages/PublicPages/About/About";
import Careers from "./pages/PublicPages/Careers/Careers";
import FeesPersonalAccount from "./pages/PublicPages/FeesPersonalAccount/FeesPersonalAccount";
import Pricing from "./pages/PublicPages/Pricing/Pricing";
import FeesBusinessAccount from "./pages/PublicPages/FeesBusinessAccount/FeesBusinessAccount";
import TermsAndConditions from "./pages/PublicPages/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./pages/PublicPages/PrivacyPolicy/PrivacyPolicy";
import CookiePolicy from "./pages/PublicPages/CookiePolicy/CookiePolicy";
import Complaints from "./pages/PublicPages/Complaints/Complaints";
import MobileHeader from "./components/MobileHeader/MobileHeader";
import ContactsForAgents from "./pages/PublicPages/ContactsForAgents/ContactsForAgents";
import { PricingBusinessAccountMobile } from './pages/PublicPages/Pricing/PricingBusinessAccountMobile';
import { PricingPersonalAccountMobile } from './pages/PublicPages/Pricing/PricingPersonalAccountMobile';
import TermsAndConditionsPrivate
    from "./pages/PublicPages/TermsAndConditions/TermsAndConditionsPrivate/TermsAndConditionsPrivate";
import TermsAndConditionsBusiness
    from "./pages/PublicPages/TermsAndConditions/TermsAndConditionsBusiness/TermsAndConditionsBusiness";
import AgentProgram from './pages/PublicPages/AgentProgram/AgentProgram';

function App() {

    return (
        <div className={s.app}>

            <div className={s.desktop_header}>
                <Header/>
            </div>

            <div className={s.mobile_header}>
                <MobileHeader/>
            </div>

            <div className={s.mobile_header}>
               <MobileHeader/>
            </div>

            <Routes>
                <Route path={'/'} element={<Home/>}/>
                <Route path={ABOUT} element={<About/>}/>
                <Route path={CAREERS} element={<Careers/>}/>
                <Route path={CONTACT_US} element={<Contacts/>}/>
                <Route path={CONTACT_US_FOR_AGENTS} element={<ContactsForAgents/>}/>
                <Route path={FAQ} element={<Faq/>}/>
                <Route path={AGENT_PROGRAM} element={<AgentProgram/>}/>
                <Route path={PERSONAL_ACCOUNT} element={<FeesPersonalAccount/>}/>
                <Route path={BUSINESS_ACCOUNT} element={<FeesBusinessAccount/>}/>
                <Route path={PRICING} element={<Pricing/>}/>
                <Route path={PRICING_PERSONAL_ACCOUNT} element={<PricingPersonalAccountMobile/>}/>
                <Route path={PRICING_BUSINESS_ACCOUNT} element={<PricingBusinessAccountMobile/>}/>
                <Route path={TERMS_AND_CONDITIONS} element={<TermsAndConditions/>}/>
                <Route path={TERMS_AND_CONDITIONS_PERSONAL} element={<TermsAndConditionsPrivate/>}/>
                <Route path={TERMS_AND_CONDITIONS_BUSINESS} element={<TermsAndConditionsBusiness/>}/>
                <Route path={PRIVACY_POLICY} element={<PrivacyPolicy/>}/>
                <Route path={COOKIE_POLICY} element={<CookiePolicy/>}/>
                <Route path={COMPLAINTS} element={<Complaints/>}/>
            </Routes>


        <Footer/>
      </div>
  );
}

export default App;
