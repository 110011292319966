import s from "./Pricing.module.css"

export const PricingPersonalAccountMobile = () => {
    return (
        <div className={s.wrapper}>
                <div className={s.container}>
                    <div className={s.title}>
                        Pricing
                    </div>
            <div className={s.title_mobile}>Personal Account</div>
                <div className={s.table_wrap}>
                            <div className={s.table_header}>
                                <span>Country of Residence (EU)</span>
                            </div>
                            <div className={s.table_body}>
                                <div className={s.table_body_subtitle}>
                                    Account opening and maintenance
                                </div>
                                <div className={s.values_block}>
                                    <div className={s.row}>
                                        <span>
                                            Account opening fee (advance payment, non. refundable if unssucessful, charged by
                                            invoice)
                                        </span>
                                        <span>€ 50</span>
                                    </div>

                                    <div className={s.row}>
                                    <span>
                                        Account maintenance fee, monthly
                                    </span>
                                        <span>€ 5 (€ 0 for first 6 months)</span>
                                    </div>

                                    <div className={s.row}>
                                    <span>
                                        Remaining balance fee for outstanding funds
                                    </span>
                                        <span>% 0</span>
                                    </div>

                                    <div className={s.row}>
                                    <span>
                                        Priority review of the application
                                    </span>
                                        <span>€ 750 – € 1000</span>
                                    </div>

                                    <div className={s.row}>
                                    <span>
                                        Inactive (after 3 month of inactivity) account maintenance fee, monthly
                                    </span>
                                        <span>€ 25</span>
                                    </div>

                                    <div className={s.row}>
                                    <span>
                                        Closing account fee
                                    </span>
                                        <span>€ 100</span>
                                    </div>
                                </div>
                            </div>
                            <div className={s.table_body}>
                                <div className={s.table_body_subtitle}>
                                    Internal payments
                                </div>

                                <div className={s.values_block}>
                                    <div className={s.row}>
                                        <span>
                                            Internal transfer
                                        </span>
                                        <span>€ 1</span>
                                    </div>
                                </div>
                            </div>
                            <div className={s.table_body}>
                                <div className={s.table_body_subtitle}>
                                    Outgoing payments
                                </div>

                                <div className={s.values_block}>
                                    <div className={s.row}>
                                        <span>
                                            SEPA / SEPA instant / GBP outgoing
                                        </span>
                                        <span>0.25 %, Min € 3</span>
                                    </div>

                                    <div className={s.row}>
                                        <span>
                                            SWIFT / Target2 (EUR only) outgoing
                                        </span>
                                        <span>0.25 %, Min € 20 + correspondent banking fees may apply</span>
                                    </div>
                                </div>
                                </div>
                            <div className={s.table_body}>
                                <div className={s.table_body_subtitle}>
                                    Incoming payments
                                </div>

                                <div className={s.values_block}>
                                    <div className={s.row}>
                                        <span>
                                            SEPA / SEPA instant / GBP outgoing
                                        </span>
                                        <span>0.2 %, Min € 3</span>
                                    </div>

                                    <div className={s.row}>
                                        <span>
                                            SWIFT / Target2 (EUR only) incoming
                                        </span>
                                        <span>0.2 %, Min € 10 + correspondent banking fees may apply</span>
                                    </div>
                                </div>
                                </div>
                            <div className={s.table_body}>
                                <div className={s.table_body_subtitle}>
                                    Other fees
                                </div>

                                <div className={s.values_block}>
                                    <div className={s.row}>
                                        <span>
                                            Payment data / Amendment / Investigation, etc.
                                        </span>
                                        <span>€ 50</span>
                                    </div>

                                    <div className={s.row}>
                                        <span>
                                            SWIFT / Target2 payment cancellation
                                        </span>
                                        <span>€ 150</span>
                                    </div>

                                    <div className={s.row}>
                                        <span>
                                            SEPA / SEPA instant / GBT payment cancellation
                                        </span>
                                        <span>€ 50</span>
                                    </div>

                                    <div className={s.row}>
                                        <span>
                                        Fee for signed account statement
                                        </span>
                                        <span>€ 50</span>
                                    </div>

                                    <div className={s.row}>
                                        <span>
                                        Additional account opening fee for complex structure of legal entities
                                        </span>
                                        <span>Min € 500 – Max € 1 000</span>
                                    </div>

                                    <div className={s.row}>
                                        <span>
                                        Currency exchange
                                        </span>
                                        <span>Up to 1.5 % (Rates may vary depending on currency pair, time, amount and other factors)</span>
                                    </div>

                                    <div className={s.row}>
                                        <span>
                                        Other fees
                                        </span>
                                        <span>Upon request</span>
                                    </div>
                                </div>

                            </div>


                            <div className={s.table_header}>
                                <span>Country of Residence (non-EU)</span>
                            </div>
                            <div className={s.table_body}>
                                <div className={s.table_body_subtitle}>
                                    Account opening and maintenance
                                </div>
                                <div className={s.values_block}>
                                    <div className={s.row}>
                                        <span>
                                            Account opening fee (advance payment, non. refundable if unssucessful, charged by invoice)
                                        </span>
                                        <span>From € 150*</span>
                                    </div>

                                    <div className={s.row}>
                                    <span>
                                        Account maintenance fee, monthly
                                    </span>
                                        <span>€ 15 (€ 0 for first 6 months)</span>
                                    </div>

                                    <div className={s.row}>
                                    <span>
                                        Remaining balance fee for outstanding funds
                                    </span>
                                        <span>% 0</span>
                                    </div>

                                    <div className={s.row}>
                                    <span>
                                        Priority review of the application
                                    </span>
                                        <span>€ 750 – € 1000</span>
                                    </div>

                                    <div className={s.row}>
                                    <span>
                                        Inactive (after 3 month of inactivity) account maintenance fee, monthly
                                    </span>
                                        <span>€ 25</span>
                                    </div>

                                    <div className={s.row}>
                                    <span>
                                        Closing account fee
                                    </span>
                                        <span>€ 100</span>
                                    </div>
                                </div>
                                </div>
                            <div className={s.table_body}>
                                <div className={s.table_body_subtitle}>
                                    Internal payments
                                </div>

                                <div className={s.values_block}>
                                    <div className={s.row}>
                                        <span>
                                            Internal transfer
                                        </span>
                                        <span>€ 1</span>
                                    </div>
                                </div>
                                </div>
                            <div className={s.table_body}>
                                <div className={s.table_body_subtitle}>
                                    Outgoing payments
                                </div>

                                <div className={s.values_block}>
                                    <div className={s.row}>
                                        <span>
                                            SEPA / SEPA instant / GBP outgoing
                                        </span>
                                        <span>0.3 %, Min € 5</span>
                                    </div>

                                    <div className={s.row}>
                                        <span>
                                            SWIFT / Target2 (EUR only) outgoing
                                        </span>
                                        <span>0.3 %, Min € 20 + correspondent banking fees may apply</span>
                                    </div>

                                    <div className={s.row}>
                                        <span>
                                            SWIFT outgoing USD payments
                                        </span>
                                        <span>0.3 %, Min 30 USD + correspondent banking fees may apply</span>
                                    </div>

                                    <div className={s.row}>
                                        <span>
                                            Fedwire outgoing USD payments
                                        </span>
                                        <span>0.2 %, Min 30 USD</span>
                                    </div>

                                    <div className={s.row}>
                                        <span>
                                            Ach outgoing USD payments
                                        </span>
                                        <span>0.2 %, Min 5 USD</span>
                                    </div>
                                </div>
                                </div>
                            <div className={s.table_body}>
                                <div className={s.table_body_subtitle}>
                                    Incoming payments
                                </div>

                                <div className={s.values_block}>
                                    <div className={s.row}>
                                        <span>
                                            SEPA / SEPA instant / GBP incoming
                                        </span>
                                        <span>0.25 %, Min € 5</span>
                                    </div>

                                    <div className={s.row}>
                                        <span>
                                            SWIFT / Target2 (EUR only) incoming
                                        </span>
                                        <span>0.25 %, Min € 10 + correspondent banking fees may apply</span>
                                    </div>
                                </div>
                                </div>
                            <div className={s.table_body}>
                                <div className={s.table_body_subtitle}>
                                    Other fees
                                </div>

                                <div className={s.values_block}>
                                    <div className={s.row}>
                                        <span>
                                            Payment data / Amendment / Investigation, etc.
                                        </span>
                                        <span>€ 50</span>
                                    </div>

                                    <div className={s.row}>
                                        <span>
                                            SWIFT / Target2 payment cancellation
                                        </span>
                                        <span>€ 150</span>
                                    </div>

                                    <div className={s.row}>
                                        <span>
                                            SEPA / SEPA instant / GBT payment cancellation
                                        </span>
                                        <span>€ 50</span>
                                    </div>

                                    <div className={s.row}>
                                        <span>
                                        Fee for signed account statement
                                        </span>
                                        <span>€ 50</span>
                                    </div>

                                    <div className={s.row}>
                                        <span>
                                        Additional account opening fee for complex structure of legal entities
                                        </span>
                                        <span>Min € 500 – Max € 1 000</span>
                                    </div>

                                    <div className={s.row}>
                                        <span>
                                        Currency exchange
                                        </span>
                                        <span>Up to 1.5 % (Rates may vary depending on currency pair, time, amount and other factors)</span>
                                    </div>

                                    <div className={s.row}>
                                        <span>
                                        Other fees
                                        </span>
                                        <span>Upon request</span>
                                    </div>
                                </div>

                            </div>

                            <div className={s.table_header}>
                                <span>Crypto, PSP, EMI, Trust’s (Business only)</span>
                            </div>
                            <div className={s.table_body}>
                                <div className={s.table_body_subtitle}>
                                    Account opening and maintenance
                                </div>
                                <div className={s.values_block}>
                                    <div className={s.row}>
                                        <span>
                                            Account opening fee (advance payment, non. refundable if unssucessful, charged by invoice)
                                        </span>
                                        <span>From € 1000*</span>
                                    </div>

                                    <div className={s.row}>
                                    <span>
                                        Account maintenance fee, monthly
                                    </span>
                                        <span>€ 50</span>
                                    </div>

                                    <div className={s.row}>
                                    <span>
                                        Remaining balance fee for outstanding funds
                                    </span>
                                        <span>% 0</span>
                                    </div>

                                    <div className={s.row}>
                                    <span>
                                        Priority review of the application
                                    </span>
                                        <span>€ 750 – € 1000</span>
                                    </div>

                                    <div className={s.row}>
                                    <span>
                                    Inactive (after 3 month of inactivity) account maintenance fee, monthly
                                    </span>
                                        <span>€ 150</span>
                                    </div>

                                    <div className={s.row}>
                                    <span>
                                        Closing account fee
                                    </span>
                                        <span>€ 100</span>
                                    </div>
                                </div>
                                </div>
                            <div className={s.table_body}>
                                <div className={s.table_body_subtitle}>
                                    Internal payments
                                </div>

                                <div className={s.values_block}>
                                    <div className={s.row}>
                                        <span>
                                            Internal transfer
                                        </span>
                                        <span>€ 1</span>
                                    </div>
                                </div>
                                </div>
                            <div className={s.table_body}>
                                <div className={s.table_body_subtitle}>
                                    Outgoing payments
                                </div>

                                <div className={s.values_block}>
                                    <div className={s.row}>
                                        <span>
                                            SEPA / SEPA instant / GBP outgoing
                                        </span>
                                        <span>0.35 %, Min € 6</span>
                                    </div>

                                    <div className={s.row}>
                                        <span>
                                        SWIFT / Target2 (EUR only) outgoing
                                        </span>
                                        <span>0.35 %, Min € 35 + correspondent banking fees may apply</span>
                                    </div>

                                    <div className={s.row}>
                                        <span>
                                            SWIFT outgoing USD payments
                                        </span>
                                        <span>0.35 %, Min 30 USD + correspondent banking fees may apply</span>
                                    </div>

                                    <div className={s.row}>
                                        <span>
                                            Fedwire outgoing USD payments
                                        </span>
                                        <span>0.2 %, Min 30 USD</span>
                                    </div>

                                    <div className={s.row}>
                                        <span>
                                            Ach outgoing USD payments
                                        </span>
                                        <span>0.2 %, Min 5 USD</span>
                                    </div>
                                </div>
                                </div>
                            <div className={s.table_body}>
                                <div className={s.table_body_subtitle}>
                                    Incoming payments
                                </div>

                                <div className={s.values_block}>
                                    <div className={s.row}>
                                        <span>
                                        SEPA / SEPA instant / GBP incoming
                                        </span>
                                        <span>0.3 %, Min € 8</span>
                                    </div>

                                    <div className={s.row}>
                                        <span>
                                        SWIFT / Target2 (EUR only) incoming
                                        </span>
                                        <span>0.35 %, Min € 40 + correspondent banking fees may apply</span>
                                    </div>
                                </div>
                                </div>
                            <div className={s.table_body}>
                                <div className={s.table_body_subtitle}>
                                    Other fees
                                </div>

                                <div className={s.values_block}>
                                    <div className={s.row}>
                                        <span>
                                            Payment data / Amendment / Investigation, etc.
                                        </span>
                                        <span>€ 150</span>
                                    </div>

                                    <div className={s.row}>
                                        <span>
                                            Fee for signed account statement
                                        </span>
                                        <span>€ 50</span>
                                    </div>

                                    <div className={s.row}>
                                        <span>
                                            Additional account opening fee for complex structure of legal entities
                                        </span>
                                        <span>Min € 500 – Max € 1 000</span>
                                    </div>

                                    <div className={s.row}>
                                        <span>
                                        Currency exchange
                                        </span>
                                        <span>Up to 1.5 % (Rates may vary depending on currency pair, time, amount and other factors)</span>
                                    </div>

                                    <div className={s.row}>
                                        <span>
                                        Other fees
                                        </span>
                                        <span>Upon request</span>
                                    </div>
                                </div>
                            </div>
                </div>
            </div>
        </div>
    )
}