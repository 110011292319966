import React from 'react';
import s from './NavBar.module.css'
import {Link} from "react-router-dom";
import classnames from "classnames";
import closePopup from "../../../assets/img/close.svg"
import logo from "../../../assets/img/logo_without_text.svg"
import TransparentButton from "../../ui/TransparentButton/TransparentButton";
import MyBtn from "../../ui/MyBtn/MyBtn";
import {
    ABOUT,
    BUSINESS_ACCOUNT,
    CONTACT_US,
    FAQ,
    PERSONAL_ACCOUNT,
    AGENT_PROGRAM
} from "../../../Constants/RoutesConstants";

type propsType = {
    isActive: boolean
    setIsActive: (boolean: boolean) => void
}
const NavBar = ({isActive, setIsActive}: propsType) => {

    const closeBurgerMenu = () => {
        setIsActive(false);
      };

    return (
        <div className={classnames(s.myPopup, isActive ? s.active : s.inactive)}>
            <div className={s.close_img_block}>
                <Link to="/" onClick={closeBurgerMenu}>
                    <img src={logo} alt="" className={s.mobile_logo}/>
                </Link>
                <img src={closePopup} className={s.closeImg} alt="" onClick={() => setIsActive(false)}/>
            </div>
            <div className={s.links_block}>
                <Link className={s.link} to={PERSONAL_ACCOUNT} onClick={closeBurgerMenu}>Personal</Link>
                <Link className={s.link} to={BUSINESS_ACCOUNT} onClick={closeBurgerMenu}>Business</Link>
                <Link className={s.link} to={ABOUT} onClick={closeBurgerMenu}>About</Link>
                <Link className={s.link} to={FAQ} onClick={closeBurgerMenu}>FAQ</Link>
                <Link className={s.link} to={AGENT_PROGRAM} onClick={closeBurgerMenu}>Agent Program</Link>
                <Link className={s.link} to={CONTACT_US} onClick={closeBurgerMenu}>Contact Us</Link>
            </div>
            <div className={s.buttons_block}>
                <Link to={"https://client.luxpay.lt/sign-in"}>
                    <TransparentButton title={"Sign In"}/>
                </Link>

                <Link to={"https://client.luxpay.lt/sign-up"}>
                    <MyBtn title={"Open account"} style={{width: "180px"}}/>
                </Link>
            </div>
        </div>
    );
};

export default NavBar;
