export const ABOUT = "/about_us"
export const CAREERS = "/careers"
export const CONTACT_US = "/contact_us"
export const CONTACT_US_FOR_AGENTS = "/contact_us_for_agents"
export const FAQ = "/faq"
export const AGENT_PROGRAM = "/agent_program"
export const PERSONAL_ACCOUNT = "/personal_account"
export const BUSINESS_ACCOUNT = "/business_account"
export const PRICING = "/pricing"
export const PRICING_PERSONAL_ACCOUNT = "/pricing/personal_account"
export const PRICING_BUSINESS_ACCOUNT = "/pricing/business_account"
export const TERMS_AND_CONDITIONS = "/terms_and_conditions"
export const TERMS_AND_CONDITIONS_PERSONAL = "/terms_and_conditions/personal"
export const TERMS_AND_CONDITIONS_BUSINESS = "/terms_and_conditions/business"
export const PRIVACY_POLICY = "/privacy_policy"
export const COOKIE_POLICY = "/cookie_policy"
export const COMPLAINTS = "/complaints"
