import React, {useState} from 'react';
import s from './MobileHeader.module.css'
import logo from '../../assets/img/logo_without_text.svg'
import burger from '../../assets/img/burger.svg'
import TransparentButton from "../ui/TransparentButton/TransparentButton";
import {Link, useNavigate} from "react-router-dom";
import NavBar from "../Header/NavBar/NavBar";
import MyBtn from "../ui/MyBtn/MyBtn";

const MobileHeader = () => {

    const [navIsShow, setNavIsShow] = useState(false)
    const navigate = useNavigate()

    return (
        <div className={s.wrapper}>
            <div className={s.logo_block}>
                <img src={logo} alt="" onClick={() => navigate("/")}/>
            </div>
            <div className={s.right}>
                <div>
                    <Link to={"https://client.luxpay.lt/sign-in"}>
                        <TransparentButton title={"Sign In"}/>
                    </Link>
                </div>
                <div>
                    <Link to={"https://client.luxpay.lt/sign-up"}>
                        <MyBtn title={"Open account"} style={{padding: "8px"}}/>
                    </Link>
                </div>
                <div>
                    <img src={burger} alt="" onClick={() => setNavIsShow(true)}/>
                </div>
            </div>

            <NavBar isActive={navIsShow} setIsActive={setNavIsShow}/>

        </div>
    );
};

export default MobileHeader;
