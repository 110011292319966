import React from 'react';
import {motion} from "framer-motion";
import s from "./Pricing.module.css"
import { Link } from 'react-router-dom';
import { PRICING_PERSONAL_ACCOUNT, PRICING_BUSINESS_ACCOUNT } from '../../../Constants/RoutesConstants';
import MyBtn from '../../../components/ui/MyBtn/MyBtn';

const Pricing = () => {
    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <div className={s.wrapper}>
                <div className={s.container}>
                    <div className={s.title}>
                        Pricing
                    </div>

                    <div className={s.table_wrapper}>
                        <div className={s.table_header}>
                            <span>Country of Residence (EU)</span>
                            <span>Personal Account</span>
                            <span>Business Account</span>
                        </div>
                        <div className={s.table_body}>
                            <div className={s.table_body_subtitle}>
                                Account opening and maintenance
                            </div>
                            <div className={s.values_block}>
                                <div className={s.row}>
                                    <span>
                                        Account opening fee (advance payment, non. refundable if unssucessful, charged by
                                        invoice)
                                    </span>
                                    <span>€ 50</span>
                                    <span>€ 150</span>
                                </div>

                                <div className={s.row}>
                                <span>
                                    Account maintenance fee, monthly
                                </span>
                                    <span>€ 5 (€ 0 for first 6 months)</span>
                                    <span>€ 25 (€ 5 for first 6 months)</span>
                                </div>

                                <div className={s.row}>
                                <span>
                                    Remaining balance fee for outstanding funds
                                </span>
                                    <span>% 0</span>
                                    <span>% 0</span>
                                </div>

                                <div className={s.row}>
                                <span>
                                    Priority review of the application
                                </span>
                                    <span>€ 750 – € 1000</span>
                                    <span>€ 750 – € 1000</span>
                                </div>

                                <div className={s.row}>
                                <span>
                                    Inactive (after 3 month of inactivity) account maintenance fee, monthly
                                </span>
                                    <span>€ 25</span>
                                    <span>€ 75</span>
                                </div>

                                <div className={s.row}>
                                <span>
                                    Closing account fee
                                </span>
                                    <span>€ 100</span>
                                    <span>€ 200</span>
                                </div>
                            </div>

                            <div className={s.table_body_subtitle}>
                                Internal payments
                            </div>

                            <div className={s.values_block}>
                                <div className={s.row}>
                                    <span>
                                        Internal transfer
                                    </span>
                                    <span>€ 1</span>
                                    <span>€ 1</span>
                                </div>
                            </div>

                            <div className={s.table_body_subtitle}>
                                Outgoing payments
                            </div>

                            <div className={s.values_block}>
                                <div className={s.row}>
                                    <span>
                                        SEPA / SEPA instant / GBP outgoing
                                    </span>
                                    <span>0.25 %, Min € 3</span>
                                    <span>0.25 %, Min € 5</span>
                                </div>

                                <div className={s.row}>
                                    <span>
                                        SWIFT / Target2 (EUR only) outgoing
                                    </span>
                                    <span>0.25 %, Min € 20 + correspondent banking fees may apply</span>
                                    <span>0.3 %, Min € 30 + correspondent banking fees may apply</span>
                                </div>
                            </div>

                            <div className={s.table_body_subtitle}>
                                Incoming payments
                            </div>

                            <div className={s.values_block}>
                                <div className={s.row}>
                                    <span>
                                        SEPA / SEPA instant / GBP outgoing
                                    </span>
                                    <span>0.2 %, Min € 3</span>
                                    <span>0.2 %, Min € 5</span>
                                </div>

                                <div className={s.row}>
                                    <span>
                                        SWIFT / Target2 (EUR only) incoming
                                    </span>
                                    <span>0.2 %, Min € 10 + correspondent banking fees may apply</span>
                                    <span>0.25 %, Min € 20 + correspondent banking fees may apply</span>
                                </div>
                            </div>

                            <div className={s.table_body_subtitle}>
                                Other fees
                            </div>

                            <div className={s.values_block}>
                                <div className={s.row}>
                                    <span>
                                        Payment data / Amendment / Investigation, etc.
                                    </span>
                                    <span>€ 50</span>
                                    <span>€ 150</span>
                                </div>

                                <div className={s.row}>
                                    <span>
                                        SWIFT / Target2 payment cancellation
                                    </span>
                                    <span>€ 150</span>
                                    <span>€ 150</span>
                                </div>

                                <div className={s.row}>
                                    <span>
                                        SEPA / SEPA instant / GBT payment cancellation
                                    </span>
                                    <span>€ 50</span>
                                    <span>€ 50</span>
                                </div>

                                <div className={s.row}>
                                    <span>
                                       Fee for signed account statement
                                    </span>
                                    <span>€ 50</span>
                                    <span>€ 50</span>
                                </div>

                                <div className={s.row}>
                                    <span>
                                      Additional account opening fee for complex structure of legal entities
                                    </span>
                                    <span>Min € 500 – Max € 1 000</span>
                                    <span>Min € 500 – Max € 1 000</span>
                                </div>

                                <div className={s.row}>
                                    <span>
                                      Currency exchange
                                    </span>
                                    <span>Up to 1.5 % (Rates may vary depending on currency pair, time, amount and other factors)</span>
                                    <span>Up to 1.5 % (Rates may vary depending on currency pair, time, amount and other factors)</span>
                                </div>

                                <div className={s.row}>
                                    <span>
                                      Other fees
                                    </span>
                                    <span>Upon request</span>
                                    <span>Upon request</span>
                                </div>
                            </div>

                        </div>


                        <div className={s.table_header}>
                            <span>Country of Residence (non-EU)</span>
                            <span>Personal Account</span>
                            <span>Business Account</span>
                        </div>
                        <div className={s.table_body}>
                            <div className={s.table_body_subtitle}>
                                Account opening and maintenance
                            </div>
                            <div className={s.values_block}>
                                <div className={s.row}>
                                    <span>
                                        Account opening fee (advance payment, non. refundable if unssucessful, charged by invoice)
                                    </span>
                                    <span>From € 150*</span>
                                    <span>From € 500*</span>
                                </div>

                                <div className={s.row}>
                                <span>
                                    Account maintenance fee, monthly
                                </span>
                                    <span>€ 15 (€ 0 for first 6 months)</span>
                                    <span>€ 50 (€ 5 for first 6 months)</span>
                                </div>

                                <div className={s.row}>
                                <span>
                                    Remaining balance fee for outstanding funds
                                </span>
                                    <span>% 0</span>
                                    <span>% 0</span>
                                </div>

                                <div className={s.row}>
                                <span>
                                    Priority review of the application
                                </span>
                                    <span>€ 750 – € 1000</span>
                                    <span>€ 1500 – € 2000</span>
                                </div>

                                <div className={s.row}>
                                <span>
                                    Inactive (after 3 month of inactivity) account maintenance fee, monthly
                                </span>
                                    <span>€ 25</span>
                                    <span>€ 75</span>
                                </div>

                                <div className={s.row}>
                                <span>
                                    Closing account fee
                                </span>
                                    <span>€ 100</span>
                                    <span>€ 200</span>
                                </div>
                            </div>

                            <div className={s.table_body_subtitle}>
                                Internal payments
                            </div>

                            <div className={s.values_block}>
                                <div className={s.row}>
                                    <span>
                                        Internal transfer
                                    </span>
                                    <span>€ 1</span>
                                    <span>€ 1</span>
                                </div>
                            </div>

                            <div className={s.table_body_subtitle}>
                                Outgoing payments
                            </div>

                            <div className={s.values_block}>
                                <div className={s.row}>
                                    <span>
                                        SEPA / SEPA instant / GBP outgoing
                                    </span>
                                    <span>0.3 %, Min € 5</span>
                                    <span>0.3 %, Min € 10</span>
                                </div>

                                <div className={s.row}>
                                    <span>
                                        SWIFT / Target2 (EUR only) outgoing
                                    </span>
                                    <span>0.3 %, Min € 20 + correspondent banking fees may apply</span>
                                    <span>0.35 %, Min € 35 + correspondent banking fees may apply</span>
                                </div>

                                <div className={s.row}>
                                    <span>
                                        SWIFT outgoing USD payments
                                    </span>
                                    <span>0.3 %, Min 30 USD + correspondent banking fees may apply</span>
                                    <span>0.5 %, Min 35 USD + correspondent banking fees may apply</span>
                                </div>

                                <div className={s.row}>
                                    <span>
                                        Fedwire outgoing USD payments
                                    </span>
                                    <span>0.2 %, Min 30 USD</span>
                                    <span>0.2 %, Min 30 USD</span>
                                </div>

                                <div className={s.row}>
                                    <span>
                                        Ach outgoing USD payments
                                    </span>
                                    <span>0.2 %, Min 5 USD</span>
                                    <span>0.2 %, Min 5 USD</span>
                                </div>
                            </div>

                            <div className={s.table_body_subtitle}>
                                Incoming payments
                            </div>

                            <div className={s.values_block}>
                                <div className={s.row}>
                                    <span>
                                        SEPA / SEPA instant / GBP incoming
                                    </span>
                                    <span>0.25 %, Min € 5</span>
                                    <span>0.2 5%, Min € 10</span>
                                </div>

                                <div className={s.row}>
                                    <span>
                                        SWIFT / Target2 (EUR only) incoming
                                    </span>
                                    <span>0.25 %, Min € 10 + correspondent banking fees may apply</span>
                                    <span>0.5 %, Min € 25 + correspondent banking fees may apply</span>
                                </div>
                            </div>

                            <div className={s.table_body_subtitle}>
                                Other fees
                            </div>

                            <div className={s.values_block}>
                                <div className={s.row}>
                                    <span>
                                        Payment data / Amendment / Investigation, etc.
                                    </span>
                                    <span>€ 50</span>
                                    <span>€ 150</span>
                                </div>

                                <div className={s.row}>
                                    <span>
                                        SWIFT / Target2 payment cancellation
                                    </span>
                                    <span>€ 150</span>
                                    <span>€ 150</span>
                                </div>

                                <div className={s.row}>
                                    <span>
                                        SEPA / SEPA instant / GBT payment cancellation
                                    </span>
                                    <span>€ 50</span>
                                    <span>€ 50</span>
                                </div>

                                <div className={s.row}>
                                    <span>
                                       Fee for signed account statement
                                    </span>
                                    <span>€ 50</span>
                                    <span>€ 50</span>
                                </div>

                                <div className={s.row}>
                                    <span>
                                      Additional account opening fee for complex structure of legal entities
                                    </span>
                                    <span>Min € 500 – Max € 1 000</span>
                                    <span>Min € 500 – Max € 1 000</span>
                                </div>

                                <div className={s.row}>
                                    <span>
                                      Currency exchange
                                    </span>
                                    <span>Up to 1.5 % (Rates may vary depending on currency pair, time, amount and other factors)</span>
                                    <span>Up to 1.5 % (Rates may vary depending on currency pair, time, amount and other factors)</span>
                                </div>

                                <div className={s.row}>
                                    <span>
                                      Other fees
                                    </span>
                                    <span>Upon request</span>
                                    <span>Upon request</span>
                                </div>
                            </div>

                            <div className={s.attention}>
                                *Final fee will be provided after first view within 24 hours
                            </div>

                        </div>

                        <div className={s.table_header}>
                            <span>Crypto, PSP, EMI, Trust’s (Business only)</span>
                            <span>Country of residence (EU)</span>
                            <span>Country of residence (non-EU)</span>
                        </div>
                        <div className={s.table_body}>
                            <div className={s.table_body_subtitle}>
                                Account opening and maintenance
                            </div>
                            <div className={s.values_block}>
                                <div className={s.row}>
                                    <span>
                                        Account opening fee (advance payment, non. refundable if unssucessful, charged by invoice)
                                    </span>
                                    <span>From € 1000*</span>
                                    <span>From € 1500*</span>
                                </div>

                                <div className={s.row}>
                                <span>
                                    Account maintenance fee, monthly
                                </span>
                                    <span>€ 50</span>
                                    <span>€ 100</span>
                                </div>

                                <div className={s.row}>
                                <span>
                                    Remaining balance fee for outstanding funds
                                </span>
                                    <span>% 0</span>
                                    <span>% 0</span>
                                </div>

                                <div className={s.row}>
                                <span>
                                    Priority review of the application
                                </span>
                                    <span>€ 750 – € 1000</span>
                                    <span>€ 750 – € 1000</span>
                                </div>

                                <div className={s.row}>
                                <span>
                                   Inactive (after 3 month of inactivity) account maintenance fee, monthly
                                </span>
                                    <span>€ 150</span>
                                    <span>€ 200</span>
                                </div>

                                <div className={s.row}>
                                <span>
                                    Closing account fee
                                </span>
                                    <span>€ 100</span>
                                    <span>€ 200</span>
                                </div>
                            </div>

                            <div className={s.table_body_subtitle}>
                                Internal payments
                            </div>

                            <div className={s.values_block}>
                                <div className={s.row}>
                                    <span>
                                        Internal transfer
                                    </span>
                                    <span>€ 1</span>
                                    <span>€ 1</span>
                                </div>
                            </div>

                            <div className={s.table_body_subtitle}>
                                Outgoing payments
                            </div>

                            <div className={s.values_block}>
                                <div className={s.row}>
                                    <span>
                                        SEPA / SEPA instant / GBP outgoing
                                    </span>
                                    <span>0.35 %, Min € 6</span>
                                    <span>0.4 %, Min € 10</span>
                                </div>

                                <div className={s.row}>
                                    <span>
                                       SWIFT / Target2 (EUR only) outgoing
                                    </span>
                                    <span>0.35 %, Min € 35 + correspondent banking fees may apply</span>
                                    <span>0.5 %, Min € 40 + correspondent banking fees may apply</span>
                                </div>

                                <div className={s.row}>
                                    <span>
                                        SWIFT outgoing USD payments
                                    </span>
                                    <span>0.35 %, Min 30 USD + correspondent banking fees may apply</span>
                                    <span>0.5 %, Min 35 USD + correspondent banking fees may apply</span>
                                </div>

                                <div className={s.row}>
                                    <span>
                                        Fedwire outgoing USD payments
                                    </span>
                                    <span>0.2 %, Min 30 USD</span>
                                    <span>0.2 %, Min 30 USD</span>
                                </div>

                                <div className={s.row}>
                                    <span>
                                        Ach outgoing USD payments
                                    </span>
                                    <span>0.2 %, Min 5 USD</span>
                                    <span>0.2 %, Min 5 USD</span>
                                </div>
                            </div>

                            <div className={s.table_body_subtitle}>
                                Incoming payments
                            </div>

                            <div className={s.values_block}>
                                <div className={s.row}>
                                    <span>
                                       SEPA / SEPA instant / GBP incoming
                                    </span>
                                    <span>0.3 %, Min € 8</span>
                                    <span>0.35%, Min € 10</span>
                                </div>

                                <div className={s.row}>
                                    <span>
                                       SWIFT / Target2 (EUR only) incoming
                                    </span>
                                    <span>0.35 %, Min € 40 + correspondent banking fees may apply</span>
                                    <span>0.5 %, Min € 3 + correspondent banking fees may apply</span>
                                </div>
                            </div>

                            <div className={s.table_body_subtitle}>
                                Other fees
                            </div>

                            <div className={s.values_block}>
                                <div className={s.row}>
                                    <span>
                                        Payment data / Amendment / Investigation, etc.
                                    </span>
                                    <span>€ 150</span>
                                    <span>€ 150</span>
                                </div>

                                <div className={s.row}>
                                    <span>
                                        Fee for signed account statement
                                    </span>
                                    <span>€ 50</span>
                                    <span>€ 50</span>
                                </div>

                                <div className={s.row}>
                                    <span>
                                        Additional account opening fee for complex structure of legal entities
                                    </span>
                                    <span>Min € 500 – Max € 1 000</span>
                                    <span>Min € 500 – Max € 1 000</span>
                                </div>

                                <div className={s.row}>
                                    <span>
                                       Currency exchange
                                    </span>
                                    <span>Up to 1.5 % (Rates may vary depending on currency pair, time, amount and other factors)</span>
                                    <span>Up to 1.5 % (Rates may vary depending on currency pair, time, amount and other factors)</span>
                                </div>

                                <div className={s.row}>
                                    <span>
                                      Other fees
                                    </span>
                                    <span>Upon request</span>
                                    <span>Upon request</span>
                                </div>
                            </div>

                            <div className={s.attention}>
                                *Final fee will be provided after first view within 24 hours
                            </div>

                        </div>
                    </div>
                    <div className={s.table_wrapper_mobile}>
                    <div className={s.container_personal_mobile}>
                    <div className={s.title_mobile}>Personal Account</div>
                        <Link to={PRICING_PERSONAL_ACCOUNT}>
                            <MyBtn title={"See more"} large />
                        </Link>
                    </div>
                    <div className={s.container_business_mobile}>
                    <div className={s.title_mobile}>Business Account</div>
                        <Link to={PRICING_BUSINESS_ACCOUNT}>
                            <MyBtn title={"See more"} large />
                        </Link>
                    </div>
                    </div>
                </div>
            </div>

        </motion.div>
    );
};

export default Pricing;
