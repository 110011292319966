import React from 'react';
import style from './header.module.css'
import {Link} from "react-router-dom";
import {
    ABOUT,
    BUSINESS_ACCOUNT,
    CONTACT_US,
    FAQ,
    PERSONAL_ACCOUNT,
    AGENT_PROGRAM
} from "../../Constants/RoutesConstants";
import TransparentButton from "../ui/TransparentButton/TransparentButton";
import MyBtn from "../ui/MyBtn/MyBtn";

const Header = () => {

    return (
        <header className={style.container}>
            <div className={style.block}>

                <div className={style.logo_block}>
                    <Link className={style.header_link} to="/">
                        <div className={style.logo}></div>
                    </Link>
                </div>

                <div className={style.links_block}>
                    <Link className={style.header_link} to={PERSONAL_ACCOUNT}>Personal</Link>
                    <Link className={style.header_link} to={BUSINESS_ACCOUNT}>Business</Link>
                    <Link className={style.header_link} to={ABOUT}>About</Link>
                    <Link className={style.header_link} to={FAQ}>FAQ</Link>
                    <Link className={style.header_link} to={AGENT_PROGRAM}>Agent Program</Link>
                    <Link className={style.header_link} to={CONTACT_US}>Contact Us</Link>
                </div>
            </div>
            <div className={style.buttons_block}>
                <div className={style.button_wrapper_short}>
                    <Link to={"https://client.luxpay.lt/sign-in"}>
                        <TransparentButton title={"Sign In"}/>
                    </Link>
                </div>
                <div className={style.button_wrapper}>
                    <Link to={"https://client.luxpay.lt/sign-up"}>
                        <MyBtn title={"Open account"}/>
                    </Link>
                </div>
            </div>
        </header>
    );
};

export default Header;
