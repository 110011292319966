import React from 'react';
import {motion} from "framer-motion";
import s from "./FeesBusinessAccount.module.css";
import {Link} from "react-router-dom";
import MyBtn from "../../../components/ui/MyBtn/MyBtn";

const FeesBusinessAccount = () => {
    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <div className={s.wrapper}>
                <div className={s.container}>
                    <div className={s.desc_block}>
                        <div className={s.title_block}>
                            <p className={s.title}>
                                <span className={s.underline}>Open Payment Account </span> to pay your suppliers and bills.
                                Or receive payments from your customers.
                            </p>
                        </div>
                        <div className={s.points_block}>
                            <p className={s.description}>
                                1. We provide SEPA transfers to our clients despite their location.
                            </p>
                            <p className={s.description}>
                                2. Cross-border multicurrency — SWIFT payments in EUR, GBP.
                            </p>
                            <p className={s.description}>
                                3. Acquiring services — <span className={s.coming_soon}>coming soon.</span>
                            </p>
                        </div>
                        <div className={s.button_wrapper}>
                            <Link to={"https://client.luxpay.lt/sign-up"}>
                                <MyBtn title={"Open Payment Account"}/>
                            </Link>
                        </div>

                        <div className={s.table_block}>
                            <div className={s.header}>
                                <span>Card fees and payment fees</span>
                                <span className={s.row_value}>Fee (EUR)</span>
                            </div>
                            <div className={s.body}>
                                <div className={s.row}>
                                    <span className={s.row_text}>Card Load Fee (%)</span>
                                    <span className={s.row_value}>00.15</span>
                                </div>

                                <div className={s.row}>
                                    <span className={s.row_text}>Purchase Fee (in-store/online) International</span>
                                    <span className={s.row_value}>00.30</span>
                                </div>

                                <div className={s.row}>
                                    <span className={s.row_text}>Purchase Fee (in-store/online) Domestic</span>
                                    <span className={s.row_value}>00.60</span>
                                </div>

                                <div className={s.row}>
                                    <span className={s.row_text}>Cash Withdrawal Domestic</span>
                                    <span className={s.row_value}>02.25</span>
                                </div>

                                <div className={s.row}>
                                    <span className={s.row_text}>Cash Withdrawal International</span>
                                    <span className={s.row_value}>03.00 <span className={s.color_value}>+ 1.00 %</span></span>
                                </div>

                                <div className={s.row}>
                                    <span className={s.row_text}>Cash Withdrawal Decline</span>
                                    <span className={s.row_value}>00.55</span>
                                </div>

                                <div className={s.row}>
                                    <span className={s.row_text}>ATM Balance Inquiry</span>
                                    <span className={s.row_value}>01.00</span>
                                </div>

                                <div className={s.row}>
                                    <span className={s.row_text}>Foreign Exchange (%)</span>
                                    <span className={s.row_value}>03.50</span>
                                </div>

                                <div className={s.row}>
                                    <span className={s.row_text}>Card Issuing*</span>
                                    <span className={s.row_value}>10.00</span>
                                </div>

                                <div className={s.row}>
                                    <span className={s.row_text}>Card Monthly fee</span>
                                    <span className={s.row_value}>05.00</span>
                                </div>
                            </div>
                            <span className={s.additional_price}>**Price for delivery by post. Delivery by courier possible for additional fee depending on the recipient’s country</span>
                        </div>

                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default FeesBusinessAccount;
